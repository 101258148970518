import FoodchainService from '../services/FoodchainService';
import Web3 from 'web3';
import { toIPFS, get_item_transaction, getItemContract, getObjectType, ITEM_STORE_IPFS_SHA256_ABI} from './utils.js';




export async function fetchData(root_id, config, callback_product, callback_merchant, callback_other, callback_downward, callback_multibatch)
{
    var DOMAIN = config.DOMAIN;
    var RPC = config.RPC;
    var CONTRACT = config.CONTRACT;
    var DEBUG = config.DEBUG;
    var IPFS_DOMAIN = config.IPFS_DOMAIN;

    var IGNORE_TEMPLATES = false;

    var HERITAGE = [];


    function debug(s){
      if(DEBUG){
       console.log(s);
      }
    }

    var root_owner = null;
    var item_tx = null;

    var DOWNWARD_EFFORT = 0;
    var UPWARD_EFFORT = 0;

    var NODE_CACHE_ACCESSES = 0;
    var NODE_CACHE = [];
    var FEEDED_NODES = 0;

    var IPFS_CACHE = [];
    var IPFS_CACHE_ACCESSES = 0;
    var FEEDED_IPFS = 0;

    var addr_void = "0x0000000000000000000000000000000000000000";
    var web3 = new Web3(new Web3.providers.HttpProvider(RPC));
    var contract_address = null;
    if(CONTRACT && CONTRACT!==''){
      contract_address = CONTRACT;
    }
    else{
      contract_address = await getItemContract(root_id, config);
    }
    var contract = new web3.eth.Contract(ITEM_STORE_IPFS_SHA256_ABI, contract_address);
    var Foodchain = new FoodchainService(DOMAIN, IPFS_DOMAIN);
    var to_do = [];

    var downward_to_do = [];
    to_do.push({id: root_id, son: null});


    var feeded_upward_nodes = [];
    //var feeded_downward_nodes = [];


    var first_product_found = false;
    var first_merchant_found = false;

    var result = [];

    // eslint-disable-next-line
    var node_count = 0;


    function get_by_id(id, res)
    {
      for(var i=0; i<res.length; i++)
      {
        if(res[i].id === id) return res[i];
      }
      return null;
    }

    function already_found(id, res)
    {
      for(var i=0; i<res.length; i++)
      {
        if(id===res[i].id) return true;
      }
      return false;
    }

    /*function type_already_found(type, res)
    {
      for(var i=0; i<res.length; i++)
      {
        if(res[i].type===type) return true;
      }
      return false;
    }*/



    function compile_products_coords(data){

      for(var i=0; i<data.products.length; i++){

        var coords_found = false;

        let the_p = data.products[i].foodchain_product;
        //console.log("EXAMINING PRODUCT "+the_p.item_id);
        var candidates = data.batch.filter((b) => {
          return b.foodchain_batch.parents.includes(the_p.item_id);
        });

        //MOCK DATA
        if(false){
          the_p.computed_coords = [44+Math.random(), 7+Math.random()];
          coords_found = true;
        }

        // PICK BATCH COORDS
        //console.log("FINDING BATCH");
        if(candidates && candidates.length > 0){
          var the_batch = candidates[0].foodchain_batch;
          //console.log("BATCH FOUND");
          //console.log(the_batch);
          if(the_batch.provenance && the_batch.provenance.latitude) {
            //console.log("BATCH COORDS SET");
            if(!coords_found){
              coords_found = true;
              the_p.computed_coords = [the_batch.provenance.latitude, the_batch.provenance.longitude];
            }
          }
        }
        // PICK PRODUCT COORDS
        if(the_p.provenance && the_p.provenance.latitude) {
          //console.log("PRODUCT COORDS SET");
          if(!coords_found){
            coords_found = true;
            the_p.computed_coords = [the_p.provenance.latitude, the_p.provenance.longitude];
          }
        }
        // PICK WAREHOUSE COORDS
        if(data.warehouse && data.warehouse.length>0) {
          //console.log("RANDOM WAREHOUSE");
          var the_w = data.warehouse[0].foodchain_warehouse;
          if(the_w.location && the_w.location.latitude) {
            //console.log("WAREHOUSE COORDS SET");
            if(!coords_found) {
              coords_found = true;
              the_p.computed_coords = [the_w.location.latitude, the_w.location.longitude];
            }
          }
        }
        // PICK MERCHANT COORDS
        if(data.merchant) {
          //console.log("MERCHANT");
          var the_m = data.merchant.foodchain_merchant;
          if(the_m.address && the_m.address.latitude) {
            //console.log("MERCHANT COORDS SET");
            if(!coords_found) {
              coords_found = true;
              the_p.computed_coords = [the_m.address.latitude, the_m.address.longitude];
            }
          }
        }
        data.products[i].foodchain_product = the_p;
      }


    }

    async function check_multibatch(item, this_obj)
    {
      var what = getObjectType(this_obj.data);
      if(what==='batch') return {"batches": [1]}; //fake array di lunghezza 1 per forzare multibatch=false (si risparmiano un po' di chiamate inutili...)
      var parent_ids = await contract.methods.getAllParentIds(item).call({from: addr_void});
      var parent_objs = [];
      for(let i = 0; i<parent_ids.length; i++)
      {
        var h_obj = await contract.methods.getItem(parent_ids[i]).call({from: addr_void});
        var hash = toIPFS(h_obj[3]);
        var pre_obj = await Foodchain.getIPFS(hash);
        var obj = pre_obj.data;
        obj.foodchain_url = parent_ids[i];
        if(obj.foodchain_batch) parent_objs.push(obj);
      }
      var result = {'batches':parent_objs, 'label':this_obj.foodchain_label};
      return result;
    }


    async function execute_upward(partial_result, current_to_do){


      if(current_to_do.length===0){
        return partial_result;
      }


      var the_todo = current_to_do.shift();
      var the_id = the_todo.id;
      var the_son_id = the_todo.son;

      debug("fetching upward "+the_id);

      if(!feeded_upward_nodes.includes(the_id)){
        UPWARD_EFFORT++;
        var node = await feed_node(the_id);

        if(node !== null && (!IGNORE_TEMPLATES || node.type!=='template')){
          downward_to_do.push(node);
          //imposta depth & mdepth
          if(the_son_id){
            var son = get_by_id(the_son_id, partial_result);
            node.son = the_son_id;
            node.depth = son.depth + 1;
            //var nnn_label = typeof(node.content["foodchain_"+node.type].name) !== 'undefined' ? node.content["foodchain_"+node.type].name : node.id;
            //var sss_label = typeof(son.content["foodchain_"+son.type].name) !== 'undefined' ? son.content["foodchain_"+son.type].name : son.id;
            //console.log("check if "+node.type+" "+nnn_label+" has diff own wrt "+son.type+" "+sss_label);
            if(node.owner !== son.owner){
              node.mdepth = son.mdepth + 1;
            }
            else
            {
              node.mdepth = son.mdepth;
            }
          }
          //console.log(node);
          feeded_upward_nodes.push(the_id);
          //bypassato
          //var cond_1 = true || !(node.mdepth > 0 && node.type !== "batch"); //non vogliamo andare sopra le cose di altri mercanti se non sono batch
          //var cond_2 = !(type_already_found("product", partial_result) && node.type === "product"); //non risaliamo oltre i products tranne quello di origine (inutile e non funge,,,TODO)
          //console.log(cond_1+" "+cond_2);
          if(true){
            var unseen_parents = node.parents.filter(function(p){ return !already_found(p, partial_result)});
            var unseen_parents_format = unseen_parents.map(function(p){ return {id:p, son:node.id};});
            current_to_do = current_to_do.concat(unseen_parents_format);
          }

          partial_result = append_result(node, partial_result);
          //partial_result.push(node);
          node_count++;
        }
      }
      return execute_upward(partial_result, current_to_do);
    }




    /*async function execute_downward(partial_result, current_to_do){

      if(current_to_do.length===0){
        return partial_result;
      }

      var the_todo = current_to_do.shift();



      while (partial_result.filter((pp)=>pp.id === the_todo.id).length>0){
        if(current_to_do.length===0){
          return partial_result;
        }
        the_todo = current_to_do.shift();
      }

      debug("ITEM "+the_todo.id);
      var the_id = the_todo.id;
      var the_parent_id = the_todo.parent;

      debug("fetching downward "+the_id);

      if(!feeded_downward_nodes.includes(the_id)){
        DOWNWARD_EFFORT++;
        var node = await feed_node(the_id);

        //imposta depth & mdepth
        if(node !== null && (!IGNORE_TEMPLATES || node.type!=='template')){



          if(the_parent_id){
            var parent = get_by_id(the_parent_id, partial_result);
            node.parent = the_parent_id;
            node.depth = parent.depth - 1;
            //var nnn_label = typeof(node.content["foodchain_"+node.type].name) !== 'undefined' ? node.content["foodchain_"+node.type].name : node.id;
            //var sss_label = typeof(son.content["foodchain_"+son.type].name) !== 'undefined' ? son.content["foodchain_"+son.type].name : son.id;
            //console.log("check if "+node.type+" "+nnn_label+" has diff own wrt "+son.type+" "+sss_label);
            if(node.owner !== parent.owner){
              node.mdepth = parent.mdepth - 1;
            }
            else
            {
              node.mdepth = parent.mdepth;
            }
          }
          //console.log(node);
          feeded_downward_nodes.push(the_id);
          //bypassato
          //var cond_1 = true || !(node.mdepth > 0 && node.type !== "batch"); //non vogliamo andare sopra le cose di altri mercanti se non sono batch
          //var cond_2 = !(type_already_found("product", partial_result) && node.type === "product"); //non risaliamo oltre i products tranne quello di origine (inutile e non funge,,,TODO)

          //console.log(cond_1+" "+cond_2);
          if(true){


            var unseen_sons = node.sons.filter(function(p){ return !already_found(p, partial_result)});
            var unseen_sons_format = unseen_sons.map(function(p){ return {id:p, parent:node.id};});


            current_to_do = current_to_do.concat(unseen_sons_format);
          }

          partial_result = append_result(node, partial_result);
          //partial_result.push(node);
          node_count++;
        }
      }
      return execute_downward(partial_result, current_to_do);
    }*/




    function append_result(node, partial_result){
        if(node.type==="product" && !first_product_found){
          callback_product(node.content);
          if(node.content && node.content.foodchain_product){
            document.title = node.content.foodchain_product.name;
          }
          first_product_found = true;
        }
        if(node.type==="merchant" && !first_merchant_found){
          callback_merchant(node.content);
          first_merchant_found = true;
        }
        //console.log("first_product_found: "+ (first_product_found?'ye':'nop'));
        partial_result.push(node);
        return partial_result;
    }


    async function find_cert_parent(obj, res) {
      var pp = obj.parents;
      for(var i=0; i<pp.length; i++)
      {
        var the_id = pp[0];
        var h_obj = await contract.methods.getItem(the_id).call({from: addr_void});
        var hash = toIPFS(h_obj[3]);
        var pre_obj = await Foodchain.getIPFS(hash);
        var the_obj = pre_obj.data;
        if(the_obj.foodchain_processing){
          return {"type": "processing", "id":the_obj.foodchain_processing.item_id};
        }
        if(the_obj.foodchain_merchant){
          return {"type": "merchant", "id":the_obj.foodchain_merchant.item_id};
        }
      }
      return "unknown";
    }


    function removePrivates(obj) {

        //console.log(obj);
        // Check if the input is an object and not null
        if (typeof obj === 'object' && obj !== null) {
            // Create an array to hold keys that need to be deleted
            const keysToDelete = [];

            // Iterate through the keys of the object
            for (const key of Object.keys(obj)) {
                //console.log("CHECKING "+key);
                //console.log(typeof obj[key]);
                // Check if the current property is an object
                if ((typeof obj[key] === 'object') && obj[key] !== null) {
                    // Recursively clean the nested objects
                    if (removePrivates(obj[key]) === null) {
                        keysToDelete.push(key); // Mark for deletion
                    }
                }
            }

            // If 'is_public' is found and is false, signal that this object should be deleted
            if (obj.is_public === false) {
                return null; // This object should be deleted
            }

            // Delete any marked keys from the object
            for (const key of keysToDelete) {
                delete obj[key];
            }
        }

        return obj; // Return the cleaned object
    }

    async function format(res){
      var rrr = {};
      rrr.batch = [];
      rrr.fields = [];
      rrr.label = [];
      rrr.merchant = {}; //object
      rrr.processings = [];
      rrr.product = {}; //object
      rrr.products = [];
      rrr.transfer = [];
      rrr.machinery = [];
      rrr.warehouse = [];
      rrr.template = [];
      rrr.certification = [];
      rrr.certification_transfer = [];

      var first_product_found = false;

      var i=0;

      //console.log(res);

      //valorizzo tag foodchain_whatever con i dati che stanno a un livello superiore
      for(i=0; i<res.length; i++){
        var el = res[i];
        if(!el.content) continue;
        var tag = "foodchain_"+el.type;

        el.content[tag].depth = el.depth;
        el.content[tag].mdepth = el.mdepth;
        el.content[tag].item_id = el.id;
        el.content[tag].parents = el.parents;
        el.content[tag].owner = el.owner;
        el.content[tag].son = el.son;
        el.content[tag].sons = el.sons;

        //riempio il risultato in uscita
        if(el.type==="batch")              rrr.batch.push({"foodchain_batch": el.content[tag]});
        if(el.type==="field" && el.mdepth===0)              rrr.fields.push({"foodchain_field": el.content[tag]});
        if(el.type==="label")                              rrr.label.push({"foodchain_label": el.content[tag]});
        if(el.type==="machinery")                          rrr.machinery.push({"foodchain_machinery": el.content[tag]});
        if(el.type==="merchant" && el.mdepth===0)           rrr.merchant = {"foodchain_merchant": el.content[tag]};
        if(el.type==="processing" && el.mdepth===0)         rrr.processings.push({"foodchain_processing": el.content[tag]});
        if(el.type==="product" && !first_product_found){
          el.content[tag].transaction_id = item_tx;
          rrr.product = {"foodchain_product": el.content[tag]};
          first_product_found = true;
        }
        else if(el.type==="product" && first_product_found){

          el.content[tag].batch_id = el.content[tag].son;
          rrr.products.push({"foodchain_product": el.content[tag]});
        }
        if(el.type==="transfer" && el.mdepth===0)           rrr.transfer.push({"foodchain_transfer": el.content[tag]});
        if(el.type==="warehouse" && el.mdepth===0)          rrr.warehouse.push({"foodchain_warehouse": el.content[tag]});
        if(el.type==="template" && el.mdepth===0)           rrr.template.push({"foodchain_template": el.content[tag]});
        if(el.type==="certification" ){
           var md = await find_cert_parent(el,res);
           el.content[tag].related_object_type = md.type;
           el.content[tag].related_object_id = md.id;
           rrr.certification.push({"foodchain_certification": el.content[tag]});
        }
        if(el.type==="certification_transfer" && el.mdepth===0)          rrr.certification_transfer.push({"foodchain_certification_transfer": el.content[tag]});
      }

      //reverse processings and templates and products:
      rrr.processings = rrr.processings.reverse();
      rrr.template = rrr.template.reverse();
      rrr.products = rrr.products.reverse();

      return removePrivates(rrr);
    }


    function Node(id){
        this.id = id;
        this.ipfs_id = null;
        this.parents = [];
        this.son = null;
        this.type=null;
        this.content=null;
        this.mdepth = 0;
        this.depth = 0;
    }


    async function parallel_data(node) {
      const obj = await get_blockchain_relations(node);
      const the_ipfs_id =  obj["ipfs_id"];
      const sons =  obj["sons"];
      const the_parents = obj["parents"];
      //const the_owner = get_owner(node);
      const the_owner = obj["owner"];

      return {
        parents: the_parents,
        sons: sons,
        ipfs_id: the_ipfs_id,
        owner: the_owner
      }
    }

    async function feed_node(node_id){
      if(NODE_CACHE[node_id]){
        NODE_CACHE_ACCESSES++;

        return NODE_CACHE[node_id];
      }

      var node = new Node(node_id);
      var pd = await parallel_data(node);
      node.parents = pd.parents;
      node.ipfs_id = pd.ipfs_id;
      node.owner = pd.owner;
      node.sons = pd.sons;
      node.content = await get_ipfs_data(node);

      FEEDED_NODES++;

      if(node.content===null){
         NODE_CACHE[node_id]=null;
         return null;
      }

      node.type = getObjectType(node.content);

      if(!root_owner){
        root_owner = pd.owner;
        var the_trans = await get_item_transaction(web3, CONTRACT, RPC, root_id, root_owner);
        if(the_trans === null || typeof the_trans === 'undefined' || the_trans === ''){
          item_tx = null;
        }
        else{
          item_tx = the_trans;
        }
      }
      NODE_CACHE[node_id]=node; // despite saving here
      return node;
    }


    async function get_ipfs_data(node){
        if(IPFS_CACHE[node.ipfs_id]){
          IPFS_CACHE_ACCESSES++;
          return IPFS_CACHE[node.ipfs_id];
        }
        debug("+getIPFS id:"+(toIPFS(node.ipfs_id)));
        var data = await Foodchain.getIPFS(toIPFS(node.ipfs_id));

        if(data === null){
          //console.log("BROKEN!!! ipfs_id "+node.ipfs_id);
          //console.log("BROKEN!!! toIPFS "+toIPFS(node.ipfs_id));
          FEEDED_IPFS++;
          IPFS_CACHE[node.ipfs_id] = null;
          return null;
        }
        FEEDED_IPFS++;
        IPFS_CACHE[node.ipfs_id] = data.data;
        return data.data;
    }

    async function get_blockchain_relations(node){
        debug("getItem id:"+(node.id));
        var obj = await contract.methods.getItem(node.id).call({from: addr_void});
        var ipfs_id = obj[3];
        var parents = obj[7];
        var sons = obj[8];
        var owner = obj[1];
        return {"ipfs_id": ipfs_id, "parents":parents, "sons":sons, "owner":owner};
    }

    /*
    async function get_owner(node){
        var ownerItem = await contract.methods.getItem(node.id).call({from: addr_void});
        //debug("[get_owner for node = "+node.id+"] "+ownerItem[1]+"");
        return ownerItem[1];

    }

    async function get_parents(node){
        debug("getAllParentIds id:"+(node.id));
        var parents = await contract.methods.getAllParentIds(node.id).call({from: addr_void});
        return parents;
    }
    */


    /*async function find_product_from_batch(b){
      var batch_id = b.id;
      var n = await feed_node(batch_id);


      var the_parents = n.parents;

      for(var i=0; i<the_parents.length; i++){
        var p = the_parents[i];
        var pn = await feed_node(p);

        if(pn){
          pn.depth = b.depth+1;
          pn.mdepth = b.mdepth; //probabilmesnte sbagliato
          if(pn.type==='product') return(pn);
        }
        else{
          console.log("DATAFETCH WARNING: batch "+batch_id+" has parent "+p+" whose IPFS data could be found. Ignoring...");
        }
      }

      return null;
    }*/



    /*function mergeFoodchainArrays(arr1, arr2, fieldname) {

      for(var i=0; i<arr2.length; i++){
        var elem = arr2[i];
        var arr1ids = arr1.map((a)=>a[fieldname].item_id);
        if(!arr1ids.includes(elem[fieldname].item_id)){
          arr1.push(elem);
        }
      }
      return arr1;
    }*/



    async function checkIfBatchesHaveAssociatedTransfer(bbb){

      for(var i=0; i<bbb.length; i++){
        var b = bbb[i].foodchain_batch;
        var batch_id = b.item_id;
        var n = await feed_node(batch_id);

        var the_sons = n.sons;

        bbb[i].foodchain_batch.has_transfer_son = false;
        for(var j=0; j<the_sons.length; j++){
          var p = the_sons[j];
          var pn = await feed_node(p);

          if(pn){

            pn.depth = b.depth+1;
            pn.mdepth = b.mdepth; //probabilmesnte sbagliato
            if(pn.type==='transfer' || pn.type==='label') {
              bbb[i].foodchain_batch.has_transfer_son = true;
            }
          }
        }
      }

      return bbb;

    }



    function retrieveKins(obj) {
      // Loop over each field in the object
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {  // Make sure the property is not inherited
          let the_obj;

          // Check if the field is an array
          if (!Array.isArray(obj[key])) {
            // If not, wrap it in a length-1 array
            the_obj = [obj[key]];
          } else {
            the_obj = obj[key];
          }

          // Now that it's guaranteed to be an array, loop over its elements
          for (let i = 0; i < the_obj.length; i++) {

            for (let subkey in the_obj[i]) {
              if (the_obj[i].hasOwnProperty(subkey)) {  // Make sure the property is not inherited

                var naked_obj = the_obj[i][subkey];
                var the_id = naked_obj.item_id;
                var the_sons = naked_obj.sons;
                var the_parents = naked_obj.parents;
                if(the_id){
                    if(!the_sons) the_sons = [];
                    if(!the_parents) the_parents = [];
                    var the_kinship = {
                      'sons' : the_sons,
                      'parents' : the_parents,
                    };
                    HERITAGE[the_id] = the_kinship;
                }
              }
            }
          }
        }
      }
    }

    // Recursive function to find all ancestors
    function findAncestors(nodeKey, visited = new Set()) {
        if (visited.has(nodeKey)) return [];
        visited.add(nodeKey);

        let ancestors = [];
        if(HERITAGE[nodeKey]){
          for (const parent of HERITAGE[nodeKey].parents) {
              ancestors.push(parent);
              ancestors.push(...findAncestors(parent, visited));
          }
        }
        return ancestors;
    }

    // Recursive function to find all descendants
    function findDescendants(nodeKey, visited = new Set()) {
        if (visited.has(nodeKey)) return [];
        visited.add(nodeKey);

        let descendants = [];
        if(HERITAGE[nodeKey]){
          for (const son of HERITAGE[nodeKey].sons) {
              descendants.push(son);
              descendants.push(...findDescendants(son, visited));
          }
        }
        return descendants;
    }


    function augmentHeritage(){
      // Augment each node with `ancestors` and `descendants`
      for (const key in HERITAGE) {
          HERITAGE[key].ancestors = Array.from(new Set(findAncestors(key))); // Remove duplicates
          HERITAGE[key].descendants = Array.from(new Set(findDescendants(key))); // Remove duplicates
      }
    }


    var this_hashes = await contract.methods.getItem(root_id).call({from: addr_void});

    //var this_obj = await Foodchain.getIPFS(toIPFS(this_hashes[3]));
    var this_obj = await Foodchain.getIPFSWithTimeout(toIPFS(this_hashes[3]), 5000);

    if(!this_obj){
      console.log("COULDNT FIND ROOT OBJECT");
      return null;
    }

    var the_type = getObjectType(this_obj.data);

    if(the_type==='label')
    {
      localStorage.setItem("pivot_element", this_obj.foodchain_label);
    }

    var multibatch_data = await check_multibatch(root_id, this_obj);

    console.log("############## multibatch_data");
    console.log(multibatch_data);

    //console.log(multibatch_data);
    if(multibatch_data && multibatch_data.batches && multibatch_data.batches.length>1){

      var the_batch = multibatch_data.batches[0];
      var node = await feed_node(the_batch.foodchain_url);
      var parents = node.parents;
      var the_product = null;
      for(var ip=0; ip<parents.length; ip++)
      {
        var p = parents[ip];
        var p_node = await feed_node(p);
        var the_obj_type = getObjectType(p_node.content);
        if(the_obj_type==='product'){
          the_product = p_node.content.foodchain_product;
        }
      }

      callback_multibatch(multibatch_data.batches,  the_product);
      return;
    }

    //Davide communciation 20241029 - if the qr is bacth or a label son of a batch (lol) ignore templates
    if(multibatch_data && multibatch_data.batches && multibatch_data.batches.length==1){
      console.log("IGNORING TEMPLATES");
      IGNORE_TEMPLATES = true;
    }

    result = await execute_upward([], to_do);
    result = await format(result);
    callback_other(result);






    //BYPASSED (Alessandro - slack 202410251622)

    /*const globals = document.getElementById('globals');
    var referer = globals.getAttribute('referer');
    console.log(referer);
    debugger;


    var downward_result = await execute_downward([], downward_to_do);
    var the_downward_batches = downward_result.filter((n)=>n.type==='batch');

    for(var i=0; i<the_downward_batches.length; i++){
      var b = the_downward_batches[i];
      var the_prod = await find_product_from_batch(b);
      if(the_prod)
      {
         downward_result.push(the_prod);
      }
    }

    downward_result = await format(downward_result);
    result.batch = mergeFoodchainArrays(result.batch, downward_result.batch, "foodchain_batch");

    result.products = mergeFoodchainArrays(result.products, downward_result.products, "foodchain_product");
    */







    compile_products_coords(result);


    result.batch = await checkIfBatchesHaveAssociatedTransfer(result.batch);

    console.log("****************************************************************");
    console.log("****************************************************************");
    console.log("****************************************************************");
    console.log("FULL OBJECT RETRIEVED");

    console.log(result);

    console.log("UPWARD EFFORT");
    console.log(UPWARD_EFFORT);
    console.log("DOWNWARD_EFFORT");
    console.log(DOWNWARD_EFFORT);
    console.log("NODE CACHE_ACCESSES");
    console.log(NODE_CACHE_ACCESSES);
    console.log("FEEDED_NODES");
    console.log(FEEDED_NODES);
    console.log("IPFS CACHE_ACCESSES");
    console.log(IPFS_CACHE_ACCESSES);
    console.log("FEEDED_IPFS");
    console.log(FEEDED_IPFS);
    console.log("****************************************************************");
    console.log("****************************************************************");
    console.log("****************************************************************");


    retrieveKins(result);
    augmentHeritage();
    console.log(HERITAGE);

    callback_downward(result);

    //that.setState({sectionData: getSectionData(result)});
    //that.setState({loaded: true, ipfs:result});
}
